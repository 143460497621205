<div>
  <div class="search-header">
    <sh-search
            size="body-2"
            class="search-box"
            [label]="'general.search' | translate"
            [value]="searchString"
            (value-changed)="onSearchString($event.target.value)"
    ></sh-search>
    <div *ngIf="!workflowGuidesService.isReleaseVersionActive()">
      <sh-button
        size="m"
        [label]="'general.add' | translate"
        [routerLink]="'phases/add'"
      ></sh-button>
    </div>
    </div>
  <sh-nav-bar slot="nav" style="position: relative">
    <sh-tabs>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.tabPhases' | translate
        "
        [active]="section === 'phases'"
        [disabled]="!id"
        (click)="section = 'phases'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.tabOverviews' | translate
        "
        [active]="section === 'overviews'"
        [disabled]="!id"
        (click)="section = 'overviews'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.tabPreview' | translate
        "
        [active]="section === 'base'"
        (click)="section = 'base'"
      ></sh-tab-item>
    </sh-tabs>
    <sh-button
      style="position: absolute; right: 0; margin: 14px"
      label="Edit"
      *ngIf="workflowGuidesService.isReleaseVersionActive()"
      (click)="workflowService.createWorkflowDraftVersion()"
    ></sh-button>
  </sh-nav-bar>

  <div class="content">
    <div *ngIf="isEditMode" [style.display]="section === 'base' ? 'block' : 'none'">
      <div class="content-row">
        <sh-card class="preview-card" label='Desktop Application' image-icon='workstation'>
          Preview the configured settings in Desktop application.
        </sh-card>
        <sh-card class="preview-card" label='iPad Application' image-icon='connected-tablet-0'>
          Preview the configured settings in Desktop ipad layout.
        </sh-card>
      </div>
    </div>
    <backend-overviews
      [style.display]="section === 'overviews' ? 'block' : 'none'"
    ></backend-overviews>
    <div
      *ngIf="isEditMode"
      [style.display]="section === 'phases' ? 'block' : 'none'"
    >
      <sh-table cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container>
          <sh-table-row>
            <sh-table-head
              unsortable
              columns="4"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.thumbnail'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head
              unsortables
              columns="4"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.name'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head
              unsortable
              columns="14"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.description'
                  | translate
              "
            ></sh-table-head>
          </sh-table-row>
        </ng-container>
        <ng-container *ngIf="assignedPhases">
          <sh-table-row
            *ngFor="let phase of phases"
            cdkDrag
            cdkDragLockAxis="y"
          >
            <sh-table-cell
              columns="4"
              [routerLink]="['phases', phase.id]"
              cdkDragHandle
            >
              <sh-table-cell columns="4">
                <img
                  *ngIf="phase.thumbnail"
                  [src]="phase.thumbnail | s3Image | async"
                  class="thumbnailImg flow-text"
                />
              </sh-table-cell>
            </sh-table-cell>
            <sh-table-cell
              columns="4"
              [routerLink]="['phases', phase.id]"
              cdkDragHandle
            >
              <p class="flow-text">
                {{ phase.name | getLocaleText : globalLanguage?.key : true }}
              </p>
            </sh-table-cell>
            <sh-table-cell
              columns="14"
              [routerLink]="['phases', phase.id]"
              cdkDragHandle
            >
              <p class="flow-text">
                {{
                  phase.description | getLocaleText : globalLanguage?.key : true
                }}
              </p>
            </sh-table-cell>
            <sh-table-cell icon columns="1" *ngIf="!workflowGuidesService.isReleaseVersionActive()">
              <sh-icon 
                button
                size="s"
                icon="delete"
                (click)="promptDeleteWorkflow(phase); $event.stopPropagation()"
              >
              </sh-icon>
            </sh-table-cell>
            <sh-table-cell icon columns="1" *ngIf="!workflowGuidesService.isReleaseVersionActive()">
              <sh-icon
                button
                size="s"
                icon="edit"
                [label]="'general.edit' | translate"
                [routerLink]="['phases', phase.id, 'edit']"
              >
              </sh-icon>
            </sh-table-cell>
            <div class="placeholder" *cdkDragPlaceholder></div>
          </sh-table-row>
        </ng-container>
      </sh-table>
    </div>
  </div>

  <div
    *ngIf="!workflowGuidesService.isReleaseVersionActive() && AgshowHeaderSave"
    class="buttons"
  >
    <sh-button
      *ngIf="section == 'base'"
      [disabled]="!deviceVersion"
      size="m"
      (click)="showReleaseWorkflowModal = true"
      [label]="'general.release' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.close' | translate"
    ></sh-button>
  </div>

  <sh-modal
    sticky
    id="sticky-modal"
    type="warning"
    label="Release Draft Version"
    modal-height="250px"
    modal-width="500px"
    [visible]="showReleaseWorkflowModal"
  >
    You have selected draft version to release. This shall release the draft
    workflow to production. Any existing released workflow will be overwritten.
    <br />
    <br />
    Do you want to continue?
    <sh-button
      color="secondary"
      slot="footer"
      label="No"
      (click)="showReleaseWorkflowModal = false"
    ></sh-button>
    <sh-button
      slot="footer"
      label="Yes"
      (click)="releaseWorkflow()"
    ></sh-button>
  </sh-modal>
  <sh-modal
    sticky
    id="sticky-modal"
    type="warning"
    [label]="'workflowguides.phases.phaseWrappers.phaseVersions.delete' | translate"
    modal-height="300px"
    modal-width="500px"
    [visible]="showDeletePhaseModal"
  >
    You are going to delete
    {{ phaseToBeDeleted?.name | getLocaleText : globalLanguage?.key : true }}.
    Deleting will permanently remove the content from CMS portal and will not be
    available for viewing the ExpertGuidance app.
    <br />
    <br />
    Do you want to delete ?
    <br />
    <br />

    <sh-button
      color="secondary"
      slot="footer"
      label="No"
      (click)="resetDeletePhaseModalValue()"
    ></sh-button>
    <sh-button slot="footer" label="Yes" (click)="deletePhase()"></sh-button>
  </sh-modal>
</div>

<div>
  <sh-nav-bar slot="nav">
    <sh-tabs>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.tabBase'
            | translate
        "
        [active]="section === 'base'"
        (click)="section = 'base'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.tabAssets'
            | translate
        "
        [active]="section === 'assets'"
        [disabled]="!id"
        (click)="section = 'assets'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.tabSimulationSetup'
            | translate
        "
        [active]="section === 'simulation'"
        [disabled]="!id"
        (click)="section = 'simulation'"
      ></sh-tab-item>
    </sh-tabs>
    <sh-button
      style="position: absolute; right: 0; margin: 14px"
      label="Edit"
      *ngIf="linksService.isReleaseVersionActive()"
      (click)="workflowService.createWorkflowDraftVersion()"
    ></sh-button>
  </sh-nav-bar>
  <div class="content" [style.display]="section === 'base' ? 'flex' : 'none'">
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.key'
            | translate
        "
      ></sh-text>
      <sh-input-text
        [disabled]="linksService.isReleaseVersionActive()"
        [error]="!isStepNameValid"
        error-message="Step with same name already exists."
        mandatory
        [value]="key"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.key'
            | translate
        "
        (value-changed)="checkForValidStepName($event.target.value)"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.interaction'
            | translate
        "
      ></sh-text>
      <sh-input-text
        [disabled]="linksService.isReleaseVersionActive()"
        mandatory
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.interaction'
            | translate
        "
        [value]="interaction"
        (value-changed)="interaction = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.layout'
            | translate
        "
      ></sh-text>
      <sh-input-text
        [disabled]="linksService.isReleaseVersionActive()"
        mandatory
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.layout'
            | translate
        "
        [value]="layout"
        (value-changed)="layout = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.instructions'
            | translate
        "
      ></sh-text>
      <sh-input-text
        [disabled]="linksService.isReleaseVersionActive()"
        mandatory
        textarea
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.instructions'
            | translate
        "
        [value]="instructionsTrans"
        (value-changed)="instructions = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.AssessmentCheckBox'
            | translate
        "
      ></sh-text>
      <sh-checkbox
        [disabled]="linksService.isReleaseVersionActive()"
        id="AssessmentBox"
        [active]="isAssessmentAvailable"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.CheckMark'
            | translate
        "
        (checked)="EnableQuestionInput($event)"
      ></sh-checkbox>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.Question'
            | translate
        "
      ></sh-text>
      <sh-input-text
        id="questionBox"
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.Question'
            | translate
        "
        [value]="questionTrans"
        (value-changed)="question = $event.target.value"
      ></sh-input-text>
    </div>
  </div>

  <div class="content" [style.display]="section === 'assets' ? 'flex' : 'none'">
    <backend-assets></backend-assets>
  </div>

  <div
    class="content"
    [style.display]="section === 'simulation' ? 'flex' : 'none'"
  >
    <div class="content-row">
      <sh-text
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.defaultAxisLockState'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        #inputDefaultAxisLockState
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        rows="1"
        [value]="defaultAxisLockState | titlecase"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.defaultAxisLockState'
            | translate
        "
        (value-changed)="defaultAxisLockState = $event.target.value"
      >
        <sh-menu-item
          *ngFor="let t of lockStateEnum | keyvalue"
          [label]="t.key"
        ></sh-menu-item>
      </sh-dropdown>
    </div>

    <div class="content-row">
      <sh-text
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.defaultControlLockState'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        #inputDefaultControlLockState
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        rows="1"
        [value]="defaultControlLockState | titlecase"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.defaultControlLockState'
            | translate
        "
        (value-changed)="defaultControlLockState = $event.target.value"
      >
        <sh-menu-item
          *ngFor="let t of lockStateEnum | keyvalue"
          [label]="t.key"
        ></sh-menu-item>
      </sh-dropdown>
    </div>

    <div class="content-row">
      <sh-text
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.systemPosition'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        #inputSystemPosition
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        id="inputSystemPosition"
        rows="1"
        [value]="systemPosition == null ? 'None' : systemPosition"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.systemPosition'
            | translate
        "
      >
        <sh-menu-item
          icon="plus"
          label="Add New System Position"
          value=" "
          (clicked)="showAddSystemPositionModal()"
        >
        </sh-menu-item>

        <sh-divider style="margin: -8px 0"> </sh-divider>
        <sh-menu-item
          *ngFor="let item of allSystemPositions; let i = index"
          [label]="item"
          id="menu{{ i }}"
          (clicked)="updateSystemPositionName(item)"
        ></sh-menu-item>
      </sh-dropdown>
    </div>

    <sh-modal
      *ngIf="addSystemPositionModalVisible"
      id="addSystemPositionContentModal"
      label="Add System Position"
      modal-width="360px"
      modal-height="''"
      (keyup.esc)="closeSystemPositionModal($event)"
      (modal-closed)="closeSystemPositionModal($event)"
      visible
    >
      <sh-input-text
        [disabled]="linksService.isReleaseVersionActive()"
        mandatory
        [visible]="true"
        id="systemPositionLabel"
        label="System Position Name"
        [value]="newSystemPositionName"
        (keyup.enter)="validateAndAdd()"
        (value-changed)="hasValidSystemPositionName($event.target.value)"
        autofocus
      >
      </sh-input-text>

      <!-- Footer -->
      <sh-button
        slot="footer"
        *ngIf="!linksService.isReleaseVersionActive()"
        label="Cancel"
        id="cancelAddNewSystemPositionName"
        (click)="closeSystemPositionModal()"
        color="secondary"
      >
      </sh-button>
      <sh-button
        slot="footer"
        *ngIf="!linksService.isReleaseVersionActive()"
        id="saveAddNewSystemPositionName"
        [disabled]="!isValid"
        (click)="$event.stopPropagation(); validateAndAdd()"
        label="Add"
      >
      </sh-button>

      <div
        *ngIf="isInvalidSystemPositionName"
        class="padding-top-12 display-inline-flex"
      >
        <sh-icon icon="error" class="icon-red"></sh-icon>
        <div>
          <sh-text class="error-text">
            {{ invalidSystemPositionNameErrorMsg }}
          </sh-text>
        </div>
      </div>
    </sh-modal>
    <sh-tabs>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.tabSimDevices'
            | translate
        "
        [active]="simSection === 'devices'"
        (click)="simSection = 'devices'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.tabSimControls'
            | translate
        "
        [active]="simSection === 'controls'"
        (click)="simSection = 'controls'"
      ></sh-tab-item>
    </sh-tabs>

    <div
      class="content"
      [style.display]="simSection === 'devices' ? 'flex' : 'none'"
    >
      <sh-grid>
        <sh-table columns="3">
          <sh-table-row>
            <sh-table-head
              unsortable
              columns="23"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.key'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head unsortable columns="1"></sh-table-head>
          </sh-table-row>

          <ng-container *ngIf="devices">
            <sh-table-row *ngFor="let device of devices">
              <sh-table-cell columns="24" (click)="selectedDevice = device">
                {{ device.key }}
              </sh-table-cell>
            </sh-table-row>
          </ng-container>

          <sh-table-row *ngIf="devices?.length === 0">
            <sh-table-cell
              columns="24"
              [innerText]="
                'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.empty'
                  | translate
              "
            >
            </sh-table-cell>
          </sh-table-row>
          <sh-button *ngIf="!linksService.isReleaseVersionActive()"
            style="margin-top: 10px"
            size="m"
            (click)="createDevice()"
            [label]="
              'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.addDevice'
                | translate
            "
          ></sh-button>
        </sh-table>
        <div columns="9">
          <ng-container *ngIf="selectedDevice">
            <div class="content-row">
              <sh-text
                size="header-3"
                [innerHTML]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.key'
                    | translate
                "
              ></sh-text>
              <sh-input-text
                [disabled]="linksService.isReleaseVersionActive()"
                #deviceKey
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.key'
                    | translate
                "
                [value]="selectedDevice.key"
              ></sh-input-text>
            </div>

            <div class="content-row">
              <sh-text
                [innerHTML]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.defaultLockState'
                    | translate
                "
              ></sh-text>
              <sh-dropdown 
                [disabled]="linksService.isReleaseVersionActive()"
                #deviceLockState
                mandatory
                rows="1"
                [value]="selectedDevice.defaultLockState | titlecase"
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.defaultLockState'
                    | translate
                "
              >
                <sh-menu-item
                  *ngFor="let t of lockStateEnum | keyvalue"
                  [label]="t.key"
                ></sh-menu-item>
              </sh-dropdown>
            </div>

            <div class="content-row" *ngIf="!linksService.isReleaseVersionActive()">
              <sh-button
                size="m"
                (click)="
                  saveDevice(
                    selectedDevice.id,
                    deviceKey.value,
                    deviceLockState.value
                  )
                "
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.save'
                    | translate
                "
              ></sh-button>
              <sh-button
                size="m"
                (click)="deleteDevice(selectedDevice.id, selectedDevice.key)"
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.delete'
                    | translate
                "
              ></sh-button>
            </div>

            <sh-table>
              <sh-table-row>
                <sh-table-head
                  unsortable
                  columns="22"
                  [innerHTML]="
                    'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.key'
                      | translate
                  "
                ></sh-table-head>
                <sh-table-head unsortable columns="2"></sh-table-head>
              </sh-table-row>
              <sh-table-row *ngFor="let axis of selectedDevice.axes">
                <sh-table-cell
                  columns="22"
                  [innerText]="axis.key"
                  (click)="editAxis(axis)"
                ></sh-table-cell>
                <sh-table-cell
                  columns="2"
                  (click)="deleteAxis(axis.id, axis.key)"
                >
                  <sh-icon button *ngIf="!linksService.isReleaseVersionActive()" icon="delete"></sh-icon>
                </sh-table-cell>
              </sh-table-row>
              <sh-button *ngIf="!linksService.isReleaseVersionActive()"
                style="margin-top: 10px"
                size="m"
                (click)="addAxis()"
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.add'
                    | translate
                "
              >
              </sh-button>
            </sh-table>
          </ng-container>
        </div>
      </sh-grid>
    </div>

    <div
      class="content"
      [style.display]="simSection === 'controls' ? 'flex' : 'none'"
    >
      <sh-grid>
        <sh-table columns="3">
          <sh-table-row>
            <sh-table-head
              unsortable
              columns="23"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.key'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head unsortable columns="1"></sh-table-head>
          </sh-table-row>

          <ng-container *ngIf="controls">
            <sh-table-row *ngFor="let control of controls">
              <sh-table-cell columns="24" (click)="selectedControl = control">
                {{ control.key }}
              </sh-table-cell>
            </sh-table-row>
          </ng-container>

          <sh-table-row *ngIf="controls?.length === 0">
            <sh-table-cell
              columns="24"
              [innerText]="
                'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.empty'
                  | translate
              "
            ></sh-table-cell>
          </sh-table-row>
        </sh-table>
        <div columns="9">
          <ng-container *ngIf="selectedControl">
            <div class="content-row">
              <sh-text
                size="header-3"
                [innerHTML]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.key'
                    | translate
                "
              ></sh-text>
              <sh-input-text
                [disabled]="linksService.isReleaseVersionActive()"
                #controlKey
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.key'
                    | translate
                "
                [value]="selectedControl.key"
              ></sh-input-text>
            </div>

            <div class="content-row">
              <sh-text
                [innerHTML]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.lockState'
                    | translate
                "
              ></sh-text>
              <sh-dropdown
                [disabled]="linksService.isReleaseVersionActive()"
                #controlLockState
                mandatory
                rows="1"
                [value]="selectedControl.lockState | titlecase"
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.lockState'
                    | translate
                "
              >
                <sh-menu-item
                  *ngFor="let t of lockStateEnum | keyvalue"
                  [label]="t.key"
                ></sh-menu-item>
              </sh-dropdown>
            </div>

            <div class="content-row" *ngIf="!linksService.isReleaseVersionActive()">
              <sh-button
                size="m"
                (click)="
                  saveControl(
                    selectedControl.id,
                    controlKey.value,
                    controlLockState.value
                  )
                "
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.save'
                    | translate
                "
              ></sh-button>
              <sh-button
                size="m"
                (click)="deleteControl(selectedControl.id, selectedControl.key)"
                [label]="
                  'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.delete'
                    | translate
                "
              ></sh-button>
            </div>
          </ng-container>
        </div>
      </sh-grid>

      <sh-button 
        *ngIf="!linksService.isReleaseVersionActive()"
        style="margin-top: 10px"
        size="m"
        (click)="createControl()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.addControl'
            | translate
        "
      ></sh-button>
    </div>
  </div>

  <div class="buttons" *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      [disabled]="
        !key || !isStepNameValid || !instructions || !interaction || !layout
      "
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>

<sh-card>
  <sh-text
    size="header-2"
    [innerHTML]="
      (isEditMode ? 'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.edit' : 'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.add') | translate
    "
  ></sh-text>
  <div class="content">
    <div class="content-row">
      <sh-input-text
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.titleCol' | translate"
        [value]="titleTrans"
        (value-changed)="checkForValidName($event.target.value)"
        [error]="!isKeyActivityNameValid"
        error-message="Key Activiy with same name already exists."
      ></sh-input-text>
    </div>
  </div>
  <div *ngIf="isEditMode">
    <div class="search-header" >
      <sh-search
              size="body-2"
              class="search-box"
              [label]="'general.search' | translate"
      ></sh-search>
      <sh-button
            *ngIf="!linksService.isReleaseVersionActive()"
            size="m"
            [label]="'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.add' | translate"
            [routerLink]="['steps', 'add']"
      ></sh-button>
    </div>
    <sh-nav-bar slot="nav" style="position: relative">
      <sh-tabs>
        <sh-tab-item
          [label]="'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.tabSteps'| translate"
          active
        ></sh-tab-item>
      </sh-tabs>
    <sh-button
      style="position: absolute; right: 0; margin: 14px"
      label="Edit"
      *ngIf="linksService.isReleaseVersionActive()"
      (click)="workflowService.createWorkflowDraftVersion()"
    ></sh-button>
    </sh-nav-bar>
    <div class="content">
      <backend-steps></backend-steps>
    </div>
  </div>
  <div class="buttons" *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      [disabled]="!(title && isKeyActivityNameValid)"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="this.showWarningModal = true"
      size="m"
      [label]="'general.close' | translate"
    ></sh-button>
  </div>
  <sh-modal
    sticky
    id="sticky-modal"
    type="warning"
    [label]="'general.warning' | translate"
    modal-height="250px"
    modal-width="400px"
    [visible]="showWarningModal"
  >
    Closing will automatically discard the modification and return to the previous screen.
    <br />
    <br />
    Are you sure you want to close ?
    <br />
    <br />
    <sh-button
      slot="footer"
      label="No"
      (click)="showWarningModal = false"
    ></sh-button>
    <sh-button
      slot="footer"
      color="secondary"
      label="Yes"
      (click)="cancel()"
    ></sh-button>
</sh-modal>
</sh-card>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../../../../services/params.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { NotificationService } from '@backend/webapp/shared/notification/notification.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyActivityService {
  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly languageService: LanguageService,
    private confirmationService: ConfirmationService,
    private spinner: SpinnerService,
    private notificationService: NotificationService,
    private readonly linksService: WorkflowGuidesService
  ) {}

  public async createKeyActivity(key: string): Promise<any> {
    const resp = await lastValueFrom(this.http.post(this.apiRoute, { key }));
    await this.linksService.loadPhaseLinks();
    await this.linksService.loadWfLinks();
    return resp;
  }

  public async editTitle(id: number, title: string) {
    return await lastValueFrom(
      this.http.put(this.apiRoute + id + '/title', {
        langKey: this.languageService.guiLanguageCode,
        title,
      })
    );
  }

  public async deleteKeyActivity(id: number, title) {
    const keyActivity = this.languageService.getTranslateValue(title);
    if (
      await this.confirmationService.confirmDelete(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.deleteConfirmation',
        { keyActivity }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(this.http.delete(this.apiRoute + id));
        await this.linksService.loadWfLinks();
        await this.linksService.loadPhaseLinks();
      } catch (e) {
        console.log(e);
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  public async editKeyActivity(id: number, key: string): Promise<any> {
    return await lastValueFrom(this.http.put(this.apiRoute + id, { key }));
  }

  public async getKeyActivity(
    id: number = this.paramsService.keyActivityId
  ): Promise<any> {
    if (id === null || id === undefined) {
      return null;
    }
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(this.http.get(this.apiRoute + id));
    this.paramsService.isLoading = false;
    return result;
  }

  private get apiRoute(): string {
    return (
      '/api/workflowguides/phases/' +
      this.paramsService.phaseWrapperId +
      '/versions/' +
      this.paramsService.phaseVersionId +
      '/keyactivities/'
    );
  }

  public async getCurrentKeyActivities(): Promise<any> {
    let result;
    if (
      this.paramsService.phaseVersionId
    ) {
      this.paramsService.isLoading = true;
      result = await lastValueFrom(this.http.get<any[]>(this.apiRoute));
      this.paramsService.isLoading = false;
    }
    return result;
  }

  public async orderKeyActivities(
    keyActivities: any[],
    previousIndex: number,
    currentIndex: number
  ): Promise<any> {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.rearrangeKeyActivities',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.rearrangeKeyActivitiesConfirmation'
      )
    ) {
      this.spinner.showSpinner();
      try {
        moveItemInArray(keyActivities, previousIndex, currentIndex);
        await lastValueFrom(
          this.http.put(this.apiRoute + 'order/keyActivities', {
            keyActivities,
          })
        );
        this.notificationService.confirmation(
          'general.success',
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.rearrangeSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.rearrangeError',
          error.error.message
        );
        return false;
      } finally {
        this.spinner.hideSpinner();
      }
    }
  }
}

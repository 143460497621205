import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { StepService } from '../step.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../../../services/language.service';
import { ParamsService } from '../../../../../../services/params.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EditAxisComponent } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/edit-step/edit-axis/edit-axis.component';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowSimulationLockState } from '@backend/api/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/workflowSimulationLockState.enum';
import { WorkflowSimulationDeviceData } from '@backend/api/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/workflowSimulationDeviceData.model';
import { WorkflowSimulationControlData } from '@backend/api/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/workflowSimulationControlData.model';
import { isEmpty } from '@backend/webapp/utilities/is-empty';
import { isInvalidFileName } from '@backend/webapp/utilities/is-invalid-file-name';
import { WorkflowService } from '@backend/webapp/workflowguides/workflows/workflow.service';

@Component({
  selector: 'backend-edit-step',
  templateUrl: './edit-step.component.html',
  styleUrls: ['./edit-step.component.scss'],
})
export class EditStepComponent implements OnInit, OnDestroy {
  public lockStateEnum = WorkflowSimulationLockState;

  private _defaultAxisLockState: WorkflowSimulationLockState = null;
  public set defaultAxisLockState(value: WorkflowSimulationLockState) {
    if (WorkflowSimulationLockState[value]) {
      value = WorkflowSimulationLockState[value];
    }

    this._defaultAxisLockState = value;
  }
  public get defaultAxisLockState(): WorkflowSimulationLockState {
    return this._defaultAxisLockState;
  }

  private _defaultControlLockState: WorkflowSimulationLockState = null;
  public set defaultControlLockState(value: WorkflowSimulationLockState) {
    if (WorkflowSimulationLockState[value]) {
      value = WorkflowSimulationLockState[value];
    }

    this._defaultControlLockState = value;
  }
  public get defaultControlLockState(): WorkflowSimulationLockState {
    return this._defaultControlLockState;
  }

  //All the system positions value from the db
  public allSystemPositions: Array<string> = [];

  //Selected system position value
  private _systemPosition: string;
  public set systemPosition(value: string) {
    if (value) {
      this._systemPosition = value;
    }
  }
  public get systemPosition(): string {
    return this._systemPosition;
  }

  public isEditMode = false;

  public id: number;
  public key: string;
  public interaction: string;
  public instructions: string;
  public layout: string;
  public question: string = '';
  public isAssessmentAvailable = false;
  private quesBkp: string;
  public assets: any[];
  public devices: WorkflowSimulationDeviceData[];
  public controls: WorkflowSimulationControlData[];

  public section = 'assets';
  public simSection = 'devices';

  public selectedDevice: WorkflowSimulationDeviceData;
  public selectedControl: WorkflowSimulationControlData;
  public initializing = true;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  instructionsTrans: any[];
  questionTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public isStepNameValid = true;
  public stepList: any[];
  public editStep: any;

  /**
   * Determines add system position modal is visible.
   */
  addSystemPositionModalVisible = false;

  isInvalidSystemPositionName = false;

  invalidSystemPositionNameErrorMsg: string;

  newSystemPositionName = '';

  public constructor(
    private readonly router: Router,
    private readonly stepService: StepService,
    private readonly paramsService: ParamsService,
    public readonly languageService: LanguageService,
    private readonly overlay: Overlay,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService,
    public readonly changeDetectorRef: ChangeDetectorRef,
    public readonly workflowService: WorkflowService
  ) {
    this.section = 'assets';
  }

  showAddSystemPositionModal(): void {
    this.addSystemPositionModalVisible = true;
  }

  closeSystemPositionModal(): void {
    this.addSystemPositionModalVisible = false;
    this.newSystemPositionName = '';
    this.updateSystemPositionName(this.systemPosition);
    this.changeDetectorRef.detectChanges();
  }

  get isValid(): boolean {
    return (
      !isEmpty(this.newSystemPositionName) && !this.isInvalidSystemPositionName
    );
  }

  hasValidSystemPositionName(systemPositionName: string): boolean {
    this.newSystemPositionName = systemPositionName.trim();
    //check whether system position name should not be repeted and without special characters.
    if (isInvalidFileName(this.newSystemPositionName)) {
      this.isInvalidSystemPositionName = true;
      this.invalidSystemPositionNameErrorMsg =
        'System Position name can\'t contain any of the following characters:\\/:*?"<>|';
      return true;
    } else if (this.isSameSystemPositionNameExists()) {
      this.isInvalidSystemPositionName = true;
      this.invalidSystemPositionNameErrorMsg =
        'A System position with the same name already exists. Please choose a different name.';
      return true;
    } else {
      this.isInvalidSystemPositionName = false;
      this.invalidSystemPositionNameErrorMsg = '';
      return false;
    }
  }

  updateSystemPositionName(newSystemPositionName: string): void {
    this.systemPosition = newSystemPositionName;
    document
      .getElementById('inputSystemPosition')
      .setAttribute('value', newSystemPositionName);
    let index = this.allSystemPositions.findIndex(
      (item) => item === this.systemPosition
    );
    document
      .getElementById('menu' + `${index}`)
      ?.setAttribute('active', 'true');
    this.changeDetectorRef.detectChanges();
  }

  isSameSystemPositionNameExists(): boolean {
    return this.allSystemPositions?.some(
      (name) =>
        name.toLowerCase().trim() ===
        this.newSystemPositionName.toLowerCase().trim()
    );
  }

  public async checkForValidStepName(key: string): Promise<void> {
    this.key = key?.trim();
    if (this.isEditMode) {
      if (this.key.toLowerCase() === this.editStep.key.trim().toLowerCase()) {
        this.isStepNameValid = true;
        return;
      }
    }

    this.isStepNameValid = this.stepList?.find(
      (step) => step.key.trim()?.toLowerCase() === this.key?.toLowerCase()
    )
      ? false
      : true;
  }

  validateAndAdd(): void {
    if (!this.isValid) {
      return;
    }
    this.allSystemPositions.push(this.newSystemPositionName);
    this.allSystemPositions.sort();
    this.stepService.createSimulationStepPosition(
      this.id,
      this.newSystemPositionName
    );
    this.updateSystemPositionName(this.newSystemPositionName);
    this.closeSystemPositionModal();

    this.changeDetectorRef.detectChanges();
  }

  public async ngOnInit(): Promise<void> {
    this.resetFields();
    await this.updateFields();
    let b = document.getElementById('AssessmentBox');
    let a = document.getElementById('questionBox');
    if (b && this.question !== null && this.question !== '') {
      this.quesBkp = this.question;
      b.setAttribute('active', 'true');
      a.setAttribute('enabled', 'true');
      this.isAssessmentAvailable = true;
    } else {
      if (a) {
        a.setAttribute('disabled', 'true');
        this.isAssessmentAvailable = false;
      }
    }
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.resetFields();
          await this.updateFields();
        }
      }
    );
    this.paramSubscription = this.paramsService.stepId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.resetFields();
          await this.updateFields();
        }
      });

    this.stepList = await this.stepService.getSteps();
    this.editStep = await this.stepService.getStep();
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private async updateFields(): Promise<void> {
    this.initializing = true;
    const step = await this.stepService.getStep();
    //Fetch all the entries of system positions from the database.
    this.allSystemPositions = await this.stepService.getSystemPositions();
    if (step) {
      this.section = 'assets';
      this.key = step.key;
      this.interaction = step.interaction;
      this.isAssessmentAvailable = step.isAssessmentAvailable;
      this.instructions = this.languageService.getTranslationByKey(
        step.instructions,
        this.globalLanguage.key
      );
      this.instructionsTrans = new GetLocaleTextPipe(
        this.localization
      ).transform(step.instructions, this.globalLanguage.key);
      this.question = this.languageService.getTranslationByKey(
        step.question,
        this.globalLanguage.key
      );
      this.questionTrans = new GetLocaleTextPipe(this.localization).transform(
        step.question,
        this.globalLanguage.key
      );
      this.assets = step.assets;
      this.layout = step.layout;
      if (this.question === '<MissingValue>' || this.question == null) {
        this.question = '';
      }
      this.id = step.id;
      if (step.simulationSetup) {
        this.defaultAxisLockState = step.simulationSetup.defaultAxisLockState;
        this.defaultControlLockState =
          step.simulationSetup.defaultControlLockState;
        this.systemPosition = step.simulationSetup.systemPosition;
        this.devices = step.simulationSetup.devices;
        this.controls = step.simulationSetup.controls;
        if (this.selectedDevice) {
          this.selectedDevice = this.devices.find(
            (d) => d.id === this.selectedDevice.id
          );
        }
      } else {
        this.defaultAxisLockState = undefined;
        this.defaultControlLockState = undefined;
        this.systemPosition = undefined;
        this.devices = [];
        this.controls = [];
      }
      this.isEditMode = true;
    } else {
      this.section = 'base';
      this.resetFields();
      this.isEditMode = false;
    }
    this.initializing = false;
  }

  private resetFields(): void {
    this.id = null;
    this.interaction = undefined;
    this.instructions = undefined;
    this.layout = undefined;
    this.question = undefined;
    this.isAssessmentAvailable = false;
    this.assets = undefined;
    this.key = '';
    this.assets = [];
    this.devices = [];
    this.controls = [];
    this.defaultAxisLockState = null;
    this.defaultControlLockState = null;
    this.systemPosition = null;
    this.addSystemPositionModalVisible = false;
  }

  public async createDevice(): Promise<void> {
    await this.stepService.createDevice(this.id);
    await this.updateFields();
    this.section = 'simulation';
  }

  public async deleteDevice(deviceId: number, key): Promise<void> {
    await this.stepService.deleteDevice(this.id, deviceId, key);
    await this.updateFields();
    this.selectedDevice = null;
    this.section = 'simulation';
  }

  public async createControl(): Promise<void> {
    await this.stepService.createControl(this.id);
    await this.updateFields();
    this.section = 'simulation';
  }

  public async deleteControl(controlId: number, key): Promise<void> {
    await this.stepService.deleteControl(this.id, controlId, key);
    await this.updateFields();
    this.selectedControl = null;
    this.section = 'simulation';
  }

  public async save() {
    let result: any;
    if (!this.isEditMode) {
      result = await this.createStep();
    } else {
      result = await this.modifyStep();
    }

    if (result) {
      await this.editInstructions(result);
      await this.editQuestion(result);
      await this.goBack();
      this.linksService.loadPhaseLinks();
      this.linksService.loadWfLinks();
    }
  }

  private async createStep(): Promise<any> {
    return await this.stepService.createStep(
        this.key,
        this.interaction,
        this.layout,
        this.isAssessmentAvailable,
        this.defaultAxisLockState,
        this.defaultControlLockState,
        this.systemPosition == 'None' ? null : this.systemPosition
      );
  }

  private async modifyStep(): Promise<any>{
    return await this.stepService.editStep(
        this.key,
        this.id,
        this.interaction,
        this.layout,
        this.isAssessmentAvailable,
        this.defaultAxisLockState,
        this.defaultControlLockState,
        this.systemPosition == 'None' ? null : this.systemPosition
      );
  }
  
  private async editInstructions(result: any): Promise<void>{
      if (
        !result.instructions ||
        this.languageService.getTranslationByKey(
          result.instructions,
          this.globalLanguage.key
        ) !== this.instructions
      ) {
        await this.stepService.editInstructions(result.id, this.instructions);
      }
  }

  private async editQuestion(result: any): Promise<void>{
        if (
          !result.question ||
          this.languageService.getTranslationByKey(
            result.question,
            this.globalLanguage.key
          ) !== this.question
        ) {
          await this.stepService.editQuestion(result.id, this.question);
        }
  }

  public async cancel() {
    await this.goBack();
  }

  public async goBack() {
    if (this.router.url.indexOf('/workflows') !== -1) {
      await this.router.navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'phases',
        this.paramsService.phaseVersionId,
        'keyactivities',
        this.paramsService.keyActivityId,
      ]);
    } else {
      await this.router.navigate([
        'workflowguides',
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
        this.paramsService.phaseVersionId,
        'keyactivities',
        this.paramsService.keyActivityId,
      ]);
    }
  }

  public async saveDevice(
    deviceId: number,
    key: string,
    lockState: WorkflowSimulationLockState
  ): Promise<void> {
    await this.stepService.editDevice(this.id, deviceId, key, lockState);
    await this.updateFields();
    this.section = 'simulation';
  }

  public async saveControl(
    controlId: number,
    key: string,
    lockState: WorkflowSimulationLockState
  ): Promise<void> {
    await this.stepService.editControl(this.id, controlId, key, lockState);
    await this.updateFields();
    this.section = 'simulation';
  }

  public EnableQuestionInput($event): void {
    let a = document.getElementById('questionBox');
    if ($event.target.active) {
      this.isAssessmentAvailable = true;
      a.attributes.removeNamedItem('disabled');
      if (this.quesBkp && this.quesBkp !== '') {
        a.setAttribute('value', this.quesBkp);
      }
    } else {
      this.quesBkp = a.getAttribute('value');
      this.isAssessmentAvailable = false;
      a.setAttribute('disabled', 'true');
      a.setAttribute('value', '');
    }
  }

  public async addAxis(): Promise<void> {
    await this.stepService.createAxis(this.selectedDevice.id);
    await this.updateFields();
    this.section = 'simulation';
  }

  public editAxis(axis: any): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayRef = this.overlay.create({
      width: 700,
      height: 400,
      hasBackdrop: true,
      positionStrategy,
    });

    const portal = new ComponentPortal(EditAxisComponent);
    const componentRef = overlayRef.attach(portal);
    componentRef.instance.init(axis);
    componentRef.instance.saveEmitter.subscribe(async (axis) => {
      await this.stepService.editAxis(axis.id, axis);
      overlayRef.detach();
      overlayRef.dispose();
      await this.updateFields();
      this.section = 'simulation';
    });

    componentRef.instance.cancelEmitter.subscribe(() => {
      overlayRef.detach();
      overlayRef.dispose();
    });
  }

  public async deleteAxis(id: number, key): Promise<void> {
    await this.stepService.deleteAxis(id, key);
    await this.updateFields();
    this.section = 'simulation';
  }
}

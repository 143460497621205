export const environment = {
  production: true,
  client_id: '3484ad82-9fdd-4312-81f9-797f2c1c4939',
  authority:
    'https://login.microsoftonline.com/5dbf1add-202a-4b8d-815b-bf0fb024e033',
  product_name: 'ExpertGuidance App',
  product_version: 'VA41A',
  front_end_version: '5.4.0',
  valUsrEmail: 'expertguidance@siemens-healthineers.com',
  domain: 'shodev.hccloud.siemens.com',
};

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '../../services/params.service';
import { WorkflowGuidesService } from '../workflowguides.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { PhaseVersionService } from './phase-versions/phase-version.service';

@Injectable({
  providedIn: 'root',
})
export class PhaseService {
  private phasenSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  public get phasen(): Observable<any[]> {
    return this.phasenSubject.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly linksService: WorkflowGuidesService,
    private confirmationService: ConfirmationService,
    private readonly phaseVersionService : PhaseVersionService
  ) {
    this.loadPhaseWrappers();
  }

  public async deletePhaseWrapper(id: number, phaseWrapper) {
    return true;
  }

  public async getPhaseWrapper(id: number = this.paramsService.phaseWrapperId) {
    if (id) {
      this.paramsService.isLoading = true;
      const result = await lastValueFrom(
        this.http.get('/api/workflowguides/phasewrappers/' + id)
      );
      this.paramsService.isLoading = false;
      return result;
    }
    return null;
  }

  public async getAllVersions() {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.get<any[]>('/api/workflowguides/phases/all')
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async loadPhaseWrappers() {
    this.paramsService.isLoading = true;
    this.phasenSubject.next(
      await lastValueFrom(this.http.get<any[]>('/api/workflowguides/phasewrappers'))
    );
    this.paramsService.isLoading = false;
    await this.linksService.loadPhaseLinks();
    return true;
  }
}

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ParamsService } from '@backend/webapp/services/params.service';
import { AssetsService } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/assets.service';
import { Router } from '@angular/router';
import { LanguageService } from '@backend/webapp/services/language.service';
import { FormControl } from '@angular/forms';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { Constants } from '@backend/interfaces';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { WorkflowService } from '@backend/webapp/workflowguides/workflows/workflow.service';

@Component({
  selector: 'backend-edit-asset',
  templateUrl: './edit-asset.component.html',
  styleUrls: ['./edit-asset.component.scss'],
})
export class EditAssetComponent implements OnInit, OnDestroy {
  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public isEditMode: boolean;
  public assetType: string;
  public section = 'hotspots';

  public id: number;
  public assetId: number;
  public target = '';

  // Image:
  public canFade = false;
  public canFadeInitial = false;

  // Video:
  public canSeek = false;
  public canSeekInitial = false;
  public startAt = 0;
  public endAt = 0;

  // Instructions:
  public indent = '';
  public displayInterval = 0;
  public fadeInDuration = 0;
  public listType = '';
  public instructions: string[] = [];

  public asset = '';
  public assetFile: File;
  public assetBaseName: string;
  public assetChanged: boolean;

  // Simulation
  public cameraTag: string;

  // For data binding purpose.. Binding directly to the instructions array would make the view rebuild each time text is entered
  public instructionFormControls: FormControl[];

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  instructionsTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private readonly paramsService: ParamsService,
    private readonly assetsService: AssetsService,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly spinner: SpinnerService,
    public readonly localization: LocalizationService,
    public readonly workflowGuidesService: WorkflowGuidesService,
    public readonly workflowService: WorkflowService
  ) {
    this.section = 'hotspots';
  }

  async ngOnInit() {
    this.updateAsset();
    this.paramSubscription = this.paramsService.assetId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          await this.updateAsset();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public async updateAsset() {
    this.assetType = this.paramsService.assetType;
    if (this.paramsService.assetId) {
      this.section = 'hotspots';
      this.isEditMode = true;
      const asset = await this.assetsService.get(this.paramsService.assetId);
      this.id = asset.id;
      this.target = asset.target;
      // Image
      if (this.assetType === 'image') {
        this.canFadeInitial = this.canFade = asset.image.canFade;
        this.asset = asset.image.path;
        this.assetId = asset.image.id;
      }
      // Video
      if (this.assetType === 'video') {
        this.canSeekInitial = this.canSeek = asset.video.canSeek;
        this.startAt = asset.video.startAt;
        this.endAt = asset.video.endAt;
        this.asset = asset.video.path;
        this.assetId = asset.video.id;
      }
      // instructions
      await this.reloadInstructions(asset);
      this.languageSubscription = this.localization.globalLanguage$.subscribe(
        async (data) => {
          if (this.router.isActive) {
            this.globalLanguage = data;
            await this.reloadInstructions(asset);
          }
        }
      );
      // Simulation
      if (this.assetType === 'simulation') {
        this.cameraTag = asset.simulation.cameraTag;
        this.assetId = asset.simulation.id;
      }

      this.assetBaseName = this.asset ? this.asset.replace(/.*[\/\\]/, '') : '';
    } else {
      this.section = 'base';
    }
  }

  async reloadInstructions(asset) {
    if (this.assetType === 'instructions') {
      this.indent = asset.instructions.indent;
      this.displayInterval = asset.instructions.displayInterval;
      this.fadeInDuration = asset.instructions.fadeInDuration;
      this.listType = asset.instructions.listType;
      this.instructions = this.languageService.getAllTranslationsByLanguageKey(
        asset.instructions.instructions,
        this.globalLanguage.key
      );
      this.instructionsTrans = [];
      for (const translation of asset.instructions.instructions) {
        const instruction = new GetLocaleTextPipe(this.localization).transform(
          [translation],
          this.globalLanguage.key
        );
        if (instruction !== '') {
          this.instructionsTrans.push(instruction);
        }
      }
      this.instructionFormControls = this.instructions
        .map(() => new FormControl())
        .map((f, i) => {
          f.setValue(this.instructions[i]);
          return f;
        });
      this.assetId = asset.instructions.id;
    }
  }

  public fileChange(file: File): void {
    if (file) {
      this.asset = file.name;
      this.assetFile = file;
      this.assetBaseName = file.name.replace(/.*[\/\\]/, '');
      this.assetChanged = true;
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
      };
      reader.readAsDataURL(file);
    } else {
      this.asset = '';
      this.assetBaseName = '';
    }
  }

  public async save() {
    let result: any;
    switch (this.assetType) {
      case 'video':
        const videoSettings = {
          target: this.target,
          canSeek: this.canSeek,
          startAt: this.startAt,
          endAt: this.endAt,
        };
        result = this.isEditMode
          ? await this.assetsService.editVideo(
              this.id,
              videoSettings,
              this.assetFile
            )
          : await this.assetsService.createVideo(videoSettings, this.assetFile);
        break;
      case 'image':
        const imageSettings = { target: this.target, canFade: this.canFade };
        result = this.isEditMode
          ? await this.assetsService.editImage(
              this.id,
              imageSettings,
              this.assetFile
            )
          : await this.assetsService.createImage(imageSettings, this.assetFile);
        break;
      case 'simulation':
        const simulationSettings = {
          target: this.target,
          cameraTag: this.cameraTag,
        };
        result = this.isEditMode
          ? await this.assetsService.editSimulation(this.id, simulationSettings)
          : await this.assetsService.createSimulation(simulationSettings);
        break;
      case 'instructions':
        const instructionsSettings = {
          target: this.target,
          indent: this.indent,
          displayInterval: this.displayInterval,
          fadeInDuration: this.fadeInDuration,
          listType: this.listType,
        };
        result = this.isEditMode
          ? await this.assetsService.editInstructions(
              this.id,
              instructionsSettings
            )
          : await this.assetsService.createInstructions(instructionsSettings);
        await this.updateInstructions(result);
        break;
    }

    await this.goBack();
  }

  public async cancel() {
    await this.goBack();
  }

  private async goBack() {
    if (this.router.url.indexOf('/workflows') !== -1) {
      await this.router.navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
        this.paramsService.phaseVersionId,
        'keyactivities',
        this.paramsService.keyActivityId,
        'steps',
        this.paramsService.stepId,
      ]);
    } else {
      await this.router.navigate([
        'workflowguides',
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
        this.paramsService.phaseVersionId,
        'keyactivities',
        this.paramsService.keyActivityId,
        'steps',
        this.paramsService.stepId,
      ]);
    }
  }

  public canSave(): boolean {
    switch (this.assetType) {
      case 'video':
        return this.target?.length > 0 && this.asset?.length > 0;
      case 'image':
        return this.target?.length > 0 && this.asset?.length > 0;
      case 'simulation':
        return this.target != null;
      case 'instructions':
        return this.target != null;
    }
  }

  public removeInstruction(i: number) {
    this.instructions = this.instructions.filter((_, index) => index !== i);
    this.instructionFormControls = this.instructionFormControls.filter(
      (_, index) => index !== i
    );
  }

  public addInstruction(): void {
    const fc = new FormControl();
    fc.setValue('');
    this.instructionFormControls.push(fc);
    this.instructions.push('');
  }

  private async updateInstructions(asset: any) {
    if (this.instructions?.length === 0) {
      return;
    }
    this.spinner.showSpinner();
    for (const translation of asset.instructions.instructions) {
      const text = this.languageService.getTranslationByKey(
        translation,
        this.globalLanguage.key
      );
      if (
        !this.instructions.find((i) => i === text) &&
        translation.languageKey === this.globalLanguage.key
      ) {
        // no such translation in the new instructions -> remove instruction
        await this.assetsService.removeInstruction(asset.id, translation);
      }
    }

    for (let i = 0; i < this.instructions.length; i++) {
      const text = this.instructionFormControls[i].value;
      if (
        text.trim() !== '' &&
        !asset.instructions.instructions.find(
          (control) =>
            this.languageService.getTranslationByKey(
              control,
              this.globalLanguage.key
            ) === text
        )
      ) {
        // new phase not yet in result -> add phase to workflowVersion
        const idx = asset.instructions.instructions.findIndex(
          (instruction) =>
            this.languageService.getTranslationByKey(
              instruction,
              this.globalLanguage.key
            ) === text
        );
        const translation = asset.instructions.instructions[idx];
        if (translation) {
          await this.assetsService.addInstruction(
            asset.id,
            text,
            translation.id
          );
        } else {
          await this.assetsService.addInstruction(asset.id, text, null);
        }
      }
    }
    this.spinner.hideSpinner();
  }

  setValue(formControl: FormControl, value: any, i) {
    if (value == null || !formControl) return;
    formControl.setValue(value);
    this.instructionFormControls[i].setValue(value);
  }

  getTranslatedValue(value) {
    return new GetLocaleTextPipe(this.localization).transform(
      value,
      this.globalLanguage.key
    );
  }
}

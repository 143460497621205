import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';

import { WorkflowService } from './workflow.service';
import { LanguageService } from '../../services/language.service';
import { PopoverService } from '../../services/popover.service';
import { WorkflowGuidesService } from '../workflowguides.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Subscription } from 'rxjs';
import {BusinessLineUtility} from "@backend/webapp/shared/businessLine/business-line.utility";
import { Workflow } from '@backend/api/WorkflowGuides/Workflow/workflow.model';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { WorkflowVersionService } from './workflow-versions/workflow-version.service';
import { WorkflowVersion } from '@backend/api/WorkflowGuides/Workflow/WorkflowVersion/workflowVersion.model';
import { NotificationService } from '@backend/webapp/shared/notification/notification.service';

@Component({
  selector: 'backend-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
})
export class WorkflowsComponent implements OnInit, OnDestroy {
  

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public statusValues = this.workflowService.getStatusEnumKeys();
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  protected readonly BusinessLineUtility = BusinessLineUtility;
  languageSubscription: Subscription;
  showDeleteWorkflowModal = false;
  workflowTobeDeleted: Workflow;
  deleteReleaseVersionReq = false;
  deleteDraftVersionReq = false;
  deleteWorkflowWithoutVersion = false;
  allWorkflows: Array<Workflow> = [];
  displayedWorkflows: Array<Workflow> = [];
  filteredAndSortedWorkflows: Array<Workflow> = [];
  pageIndex = 1;
  itemsPerPage = 20;
  searchString = '';
  isAscending = true;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly workflowService: WorkflowService,
    public readonly router: Router,
    public readonly linksService: WorkflowGuidesService,
    public readonly paramService: ParamsService,
    public readonly localization: LocalizationService,
    public readonly spinnerService: SpinnerService,
    public readonly workflowVersionService: WorkflowVersionService,
    public readonly notificationService: NotificationService
  ) {
    this.workflowService.workflows.subscribe(() => {
      this.displayedWorkflows = this.workflowService.workflows$;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
        }
      }
    );
    await this.workflowService.loadWorkflows();
    this.allWorkflows = this.workflowService.workflows$;
    this.filteredAndSortedWorkflows = this.allWorkflows;
    this.manageWorkflowsInfiniteScroll();
  }

  public promptDeleteWorkflow(workflow: Workflow): void {
    this.showDeleteWorkflowModal = true;
    this.workflowTobeDeleted = workflow;

    //Set the checkbox values  
    this.deleteReleaseVersionReq = workflow.workflowReleasedVersion && !workflow.workflowDraftVersion;
    this.deleteDraftVersionReq = workflow.workflowDraftVersion && !workflow.workflowReleasedVersion;
    this.deleteWorkflowWithoutVersion = !workflow.workflowReleasedVersion && !workflow.workflowDraftVersion;
  }

  public async deleteWorkflow(): Promise<void> {
    this.showDeleteWorkflowModal = false;
    this.spinnerService.showSpinner();

    let isDraftDeleted = false;
    if (this.deleteDraftVersionReq) {
      isDraftDeleted = await this.deleteDraftVersion(this.workflowTobeDeleted);
    }

    let isReleaseDeleted = false;
    if (this.deleteReleaseVersionReq) {
        isReleaseDeleted = await this.deleteReleaseVersion(this.workflowTobeDeleted);
    }

    let shouldWorkflowToBeDeleted = (isReleaseDeleted && isDraftDeleted)
      || (isReleaseDeleted && !this.workflowTobeDeleted.workflowDraftVersion)
      || (isDraftDeleted && !this.workflowTobeDeleted.workflowReleasedVersion)
      || this.deleteWorkflowWithoutVersion;
    
    if (shouldWorkflowToBeDeleted) { 
        await this.workflowService.deleteWorkflow(this.workflowTobeDeleted);
    } 

    await this.workflowService.loadWorkflows();
    this.resetDeleteWorkflowModalValue();
    this.spinnerService.hideSpinner();
  }

  private async deleteDraftVersion(workflow: Workflow): Promise<boolean> {
    let isDraftHasDependency = await this.workflowService.checkForDraftVersionDependency(workflow);
    
    if (isDraftHasDependency) {
      this.notifyAlert('workflowguides.workflows.deleteDraftWarning');
      return false;
    }

    return await this.workflowVersionService.deleteWorkflowVersion(workflow.workflowDraftVersion.id);
  }

  private async deleteReleaseVersion(workflow: Workflow): Promise<boolean> {
    let isReleaseHasDependency = await this.workflowService.checkForReleaseVersionDependency(workflow);

    if (isReleaseHasDependency) {
      this.notifyAlert('workflowguides.workflows.deleteReleaseWarning');
      return false;
    }

    return await this.deleteReleaseVersions(workflow);
  }

  // Notification Helper Functions
  private notifyAlert(messageKey: string): void {
    this.notificationService.alert('general.warning', messageKey, null);
  }

  private async deleteReleaseVersions(workflow: Workflow): Promise<boolean> {
    let workflowReleaseVersions: WorkflowVersion[] = [];

    if (workflow.workflowReleasedVersion) {
      workflowReleaseVersions = workflow.versions.filter(
        (workflowVersion) =>
          workflowVersion.id !== workflow.workflowDraftVersion?.id
      );
    }

    let isReleaseVersionDeleted = false;
    for (const version of workflowReleaseVersions) {
      isReleaseVersionDeleted = await this.workflowVersionService.deleteWorkflowVersion(version.id);
      if (!isReleaseVersionDeleted) {
        break;
      }
    }

    return isReleaseVersionDeleted;
  }

  public getallWorkflowsCount(): string {
    if (!this.allWorkflows?.length) {
      return '';
    }

    return `(${this.allWorkflows.length})`;
  }

  public onScrollEnd(): void {
    this.pageIndex = this.pageIndex + 1;
    this.manageWorkflowsInfiniteScroll();
  }

  private manageWorkflowsInfiniteScroll(): void {
    const startIndex = (this.pageIndex - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if (this.pageIndex == 1) {
      this.displayedWorkflows = [];
    }

    for (let i = startIndex; i < endIndex; i++) {
      if (i < this.filteredAndSortedWorkflows.length) {
        this.displayedWorkflows.push(this.filteredAndSortedWorkflows[i]);
      }
    }
  }


  public resetDeleteWorkflowModalValue() {
    this.showDeleteWorkflowModal = false
    this.workflowTobeDeleted = null;
    this.deleteReleaseVersionReq = false;
    this.deleteDraftVersionReq = false;
    this.deleteWorkflowWithoutVersion = false;
    this.isAscending = false;
    this.toggleSort();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public toggleSort(): void {
    this.isAscending = !this.isAscending;
    this.pageIndex = 1;
    this.sortWorkflowsByName();
    this.manageWorkflowsInfiniteScroll();
  }

  private sortWorkflowsByName(): void {
    const sortedWorkflows = this.filteredAndSortedWorkflows.slice().sort(
      (a, b) =>
        (this.isAscending ? -1 : 1) *
        this.languageService.getTranslationByKey(b.title, this.globalLanguage.key).localeCompare(
          this.languageService.getTranslationByKey(a.title, this.globalLanguage.key)
        )
    );
    this.filteredAndSortedWorkflows = sortedWorkflows;
  }
  
  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.pageIndex = 1;
    this.searchWorkflowsByNameOrDescription();
    this.manageWorkflowsInfiniteScroll();
  }

  private searchWorkflowsByNameOrDescription(): void {
    if (!this.searchString) {
      this.filteredAndSortedWorkflows = this.allWorkflows;
    } else {
      const searchText = this.searchString.toLocaleLowerCase();
      this.filteredAndSortedWorkflows = this.allWorkflows.filter(
        (workflow) =>
          this.languageService.getTranslationByKey(workflow.title, this.globalLanguage.key)
            .toLocaleLowerCase()
            .includes(searchText) ||
          this.languageService.getTranslationByKey(workflow.description, this.globalLanguage.key)
            .toLocaleLowerCase()
            .includes(searchText)
      );
    }

    this.sortWorkflowsByName();
  }
}

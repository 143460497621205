import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { WorkflowGuidesService } from './workflowguides.service';
import { LanguageService } from '../services/language.service';
import { PopoverService } from '../services/popover.service';
import { DeviceVersionsService } from '../devices/device-versions/device-versions.service';
import { Router } from '@angular/router';
import { ParamsService } from '../services/params.service';
import { Constants, Status } from '@backend/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { BlocksService } from './workflows/workflow-versions/overviews/blocks/blocks.service';
import { LocalizationService } from '../shared/localization/localization.service';
import { OverviewsService } from './workflows/workflow-versions/overviews/overviews.service';

import { AssetsService as StepAssetsService } from './phases/phase-versions/key-activities/steps/assets/assets.service';
import { HotspotsService as StepAssetHotspotsService } from './phases/phase-versions/key-activities/steps/assets/hotspots/hotspots.service';
import { Breadcrumb } from '../shared/breadcrumb/breadcrumb.interface';
import { GetLocaleTextPipe } from '../get-locale-text.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-workflowguides',
  templateUrl: './workflowguides.component.html',
  styleUrls: ['./workflowguides.component.scss'],
})
export class WorkflowGuidesComponent implements OnInit, OnDestroy {
  workflowsActive = true;
  phasesActive = false;
  public workflowLinks = this.linksService.wflinks;
  public phaseLinks = this.linksService.phaselinks;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  private _links: Array<Breadcrumb> = [];
  public get links(): Array<Breadcrumb> {
    return this._links;
  }

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly linksService: WorkflowGuidesService,
    public readonly deviceVersionsService: DeviceVersionsService,
    public readonly router: Router,
    private readonly translateService: TranslateService,
    public readonly paramsService: ParamsService,
    private readonly stepAssetsService: StepAssetsService,
    private readonly stepAssetHotspotsService: StepAssetHotspotsService,
    private readonly overviewsService: OverviewsService,
    private readonly blocksService: BlocksService,
    private readonly localization: LocalizationService
  ) {}

  ngOnInit(): void { 
    this.paramSubscription = this.paramsService.updated.subscribe(() => {
      this.linksService.loadWfLinks();
    });
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      (data) => {
        this.globalLanguage = data;
        this.linksService.loadWfLinks();
      }
    );
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  isActive(element: string, elementId: number): boolean {
    switch (element) {
      case 'workflow':
        return (
          this.paramsService.workflowId === elementId &&
          this.router.url.indexOf('/workflows') !== -1 &&
          this.router.url.indexOf('/versions/') === -1
        );
      case 'workflowVersion':
        return (
          this.paramsService.workflowVersionId === elementId &&
          this.router.url.indexOf('/workflows') !== -1 &&
          this.router.url.indexOf('/versions/') !== -1 &&
          this.router.url.indexOf('/phases') === -1
        );
      case 'phase':
        return (
          this.paramsService.phaseWrapperId === elementId &&
          this.router.url.indexOf('/phases') !== -1 &&
          this.router.url.indexOf('/versions/') === -1 &&
          this.router.url.indexOf('/keyactivities') === -1 &&
          this.router.url.indexOf('/steps') === -1
        );
      case 'phaseVersion':
        return (
          this.paramsService.phaseVersionId === elementId &&
          this.router.url.indexOf('/phases') !== -1 &&
          this.router.url.indexOf('/versions/') !== -1 &&
          this.router.url.indexOf('/keyactivities') === -1 &&
          this.router.url.indexOf('/steps') === -1
        );
      case 'keyActivity':
        return (
          this.paramsService.keyActivityId === elementId &&
          this.router.url.indexOf('/phases') !== -1 &&
          this.router.url.indexOf('/versions/') !== -1 &&
          this.router.url.indexOf('/keyactivities') !== -1 &&
          this.router.url.indexOf('/steps') === -1
        );
      case 'step':
        return (
          this.paramsService.stepId === elementId &&
          this.router.url.indexOf('/phases') !== -1 &&
          this.router.url.indexOf('/versions/') !== -1 &&
          this.router.url.indexOf('/keyactivities') !== -1 &&
          this.router.url.indexOf('/steps') !== -1
        );
      default:
        return false;
    }
  }

  isLatest(versions: any[], version: any) {
    versions = versions
      .filter((x) => x.status === Status.RELEASE)
      .sort((p, c) => (p.appVersion > c.appVersion ? 1 : -1));
    if (versions.length < 1) {
      return false;
    }
    const rec = versions.reduce((p, c) => (p.version > c.version ? p : c));
    if (!rec) {
      return false;
    }
    if (rec === version) {
      return true;
    }
    return false;
  }

  sortByOrder(list: any) {
    if (!list) {
      return;
    }
    return list.sort((a, b) => a.order - b.order);
  }

  sortByPhaseOrder(phases: any[], phaseOrder: any[]) {
    let sortedPhases = [];
    if (phaseOrder.length === 0) {
      phases.sort((a, b) => a.id - b.id);
      sortedPhases = phases;
    } else {
      phaseOrder.sort((a, b) => a.order - b.order);
      phaseOrder.forEach((element) => {
        sortedPhases.push(
          phases.find((phase) => element.phase.id === phase.id)
        );
      });
    }
    return sortedPhases;
  }

  public getStatusEnumKeys(): Array<string> {
    const keys = Object.keys(Status);
    return keys.slice(keys.length / 2, keys.length);
  }

  async setPhaseVersionLinks(phaseVersion, tempLinks) {
    if (phaseVersion) {
      let category = this.translateService.instant(
        'workflowguides.phases.phaseWrappers.phaseVersions.category'
      );
      const phaseVersionName = new GetLocaleTextPipe(
        this.localization
      ).transform(phaseVersion.name, this.globalLanguage.key, true);
      tempLinks.push({
        name: phaseVersionName,
        url: `/workflowguides/phases/${phaseVersion.id}/`,
        category,
      });

      const keyActivity: any = await this.getKeyActivity();
      if (keyActivity) {
        category = this.translateService.instant(
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.category'
        );
        const keyActivityTitle = new GetLocaleTextPipe(
          this.localization
        ).transform(keyActivity.title, this.globalLanguage.key, true);
        tempLinks.push({
          name: keyActivityTitle,
          url: `/workflowguides/phases/${phaseVersion.id}/keyactivities/${keyActivity.id}/`,
          category,
        });

        const step: any = await this.getStep();
        if (step) {
          category = this.translateService.instant(
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.category'
          );
          tempLinks.push({
            name: step.key,
            url: `/workflowguides/phases/${phaseVersion.id}/keyactivities/${keyActivity.id}/steps/${step.id}/`,
            category,
          });

          const asset: any = await this.stepAssetsService.get();
          if (asset) {
            const assetType = this.paramsService.assetType;
            category = this.translateService.instant(
              'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.category'
            );
            tempLinks.push({
              name: '#' + asset.id.toString(),
              url: `/workflowguides/phases/${phaseVersion.id}/keyactivities/${keyActivity.id}/steps/${step.id}/assets/${assetType}/${asset.id}`,
              category,
            });

            const hotspot: any = await this.stepAssetHotspotsService.get();
            if (hotspot) {
              category = this.translateService.instant(
                'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.category'
              );
              tempLinks.push({
                name: '#' + hotspot.id.toString(),
                url: `/workflowguides/phases/${phaseVersion.id}/keyactivities/${keyActivity.id}/steps/${step.id}/assets/${assetType}/${asset.id}/hotspots/${hotspot.id}`,
                category,
              });
            }
          }
        }
      }
    }
    return tempLinks;
  }

  async setOverviewLinks(overview, tempLinks, workflow, workflowVersion) {
    if (overview) {
      let category = this.translateService.instant(
        'workflowguides.workflows.workflowVersions.overviews.category'
      );
      tempLinks.push({
        name: new GetLocaleTextPipe(this.localization).transform(
          overview.title,
          this.globalLanguage.key,
          true
        ),
        url: `/workflowguides/workflows/${workflow.id}/versions/${workflowVersion.id}/overviews/${overview.id}`,
        category,
      });
      const block: any = this.paramsService.blockId
        ? await this.blocksService.get(this.paramsService.blockId)
        : null;
      if (block) {
        category = this.translateService.instant(
          'workflowguides.workflows.workflowVersions.overviews.blocks.category'
        );
        tempLinks.push({
          name: '#' + block.id.toString(),
          url: `/workflowguides/workflows/${workflow.id}/versions/${workflowVersion.id}/overviews/${overview.id}`,
          category,
        });
        const author: any = this.paramsService.authorId
          ? await this.blocksService.getAuthor(this.paramsService.authorId)
          : null;
        if (author) {
          category = this.translateService.instant(
            'workflowguides.workflows.workflowVersions.overviews.blocks.authors.category'
          );
          tempLinks.push({
            name: new GetLocaleTextPipe(this.localization).transform(
              author.name,
              this.globalLanguage.key,
              true
            ),
            url: `/workflowguides/workflows/${workflow.id}/versions/${workflowVersion.id}/overviews/${overview.id}/author/${author.id}`,
            category,
          });
        }
      }
    }
    return tempLinks;
  }

  getPhaseWrapper() {
    let phaseWrapper;
    this.linksService.phaselinks.subscribe((data) => {
      phaseWrapper = data.find(
        (phase) => phase.id === this.paramsService.phaseWrapperId
      );
    });
    return phaseWrapper;
  }

  getWorkflow() {
    let workflow;
    this.linksService.wflinks.subscribe((data) => {
      workflow = data.find((wf) => wf.id === this.paramsService.workflowId);
    });
    return workflow;
  }

  getPhaseVersion() {
    const phase = this.getPhaseWrapper();
    return phase.versions.find(
      (ver) => ver.id === this.paramsService.phaseVersionId
    );
  }

  getKeyActivity() {
    const version = this.getPhaseVersion();
    return version.keyActivities.find(
      (ka) => ka.id === this.paramsService.keyActivityId
    );
  }

  getStep() {
    const keyActivity = this.getKeyActivity();
    return keyActivity.steps.find((st) => st.id === this.paramsService.stepId);
  }

  getWorkflowVersion() {
    const workflow = this.getWorkflow();
    return workflow.versions.find(
      (ver) => ver.id === this.paramsService.workflowVersionId
    );
  }
}
